<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-21 15:05:55
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\gridManage\index.vue
 * @Description: 
-->
<template>
	<div class="grid">
		<div class="md-layout" v-loading="loading">
			<div class="sq-grid head-shadow">
				<div class="sq-grid-img">
					<el-image :src="gridInfo.logo" fit="fill">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
				</div>
				<div class="sq-grid-info">
					<div class="md-title mt-10">{{ gridInfo.gridName || '--' }}</div>
					<div class="mt-10">网格长：{{ gridInfo.realName || '--' }}
						<!-- <p class="md-display-1">某某某</p> -->
					</div>
					<div class="mt-5">介绍说明：{{ gridInfo.remark || '--' }}</div>
				</div>
				<div class="sq-grid-btn">
					<md-button class="md-success" @click="editGrid">编 辑</md-button>
				</div>
			</div>
			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 mt-20">
				<md-card class="md-card-plain">
					<md-card-header class="card-header" md-alignment="center">
						<!-- <h4 class="title">用户列表</h4> -->
						<!-- <p class="category"> -->
						<md-button class="md-success" @click="addUser"><i class="el-icon-plus mr-5"></i>添加网格</md-button>
						<!-- </p> -->
					</md-card-header>
					<md-card-content class="card-content">
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="编号" width="100">
							</el-table-column>
							<el-table-column prop="gridName" label="网格名称" min-width="120">
							</el-table-column>
							<el-table-column prop="realName" label="管理员" min-width="120">
							</el-table-column>
							<!-- mobile -->
							<el-table-column prop="areaBding" label="范围" min-width="120">
								<template slot-scope="scope">
									<span v-if="scope.row.areaBdingShow">{{ scope.row.areaBdingShow.join('，') }}</span>
									<!-- <span v-if="scope.row.areaBding">{{ scope.row.areaBding.join('/') }}</span> -->
								</template>
							</el-table-column>
							<el-table-column prop="remark" label="说明" min-width="120">
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text" @click="editGridInfo(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delGrid(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.gridName} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
					</md-card-content>
				</md-card>
			</div>
		</div>
		<!-- 添加编辑用户 -->
		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="formRules" :label-position="labelPosition" label-width="80px"
				:model="gridForm">
				<el-form-item label="网格名称" prop="gridName">
					<el-input v-model="gridForm.gridName" placeholder="请输入网格名称"></el-input>
				</el-form-item>
				<el-form-item label="管理员" prop="uid">
					<el-select v-model="gridForm.uid" placeholder="请选择管理员" class="auth-select">
						<el-option v-for="item in userList" :key="item.id" :label="item.realName"
							:value="item.id + ''"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="管理范围" prop="areaBding">
					<el-cascader style="width: 100%;" placeholder="请选择管理范围" v-model="gridForm.areaBding" :props="props"
						:options="communityList"></el-cascader>
					<!-- <el-select v-model="gridForm.areaBding" multiple placeholder="请选择工作条线" class="auth-select">
						<el-option v-for="item in workLimitList" :key="item.id" :label="item.realName"
							:value="item.id"></el-option>
					</el-select> -->
				</el-form-item>
				<el-form-item label="说明">
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请输入说明"
						v-model="gridForm.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="subUser">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 分配角色弹出框 -->
		<el-dialog title="社区网格编辑" :visible.sync="roleDialogVisible" width="35%" :before-close="roleDialogClose">
			<el-form ref="dialogForm2" :rules="formRules2" :label-position="labelPosition" label-width="80px"
				:model="gridForm2">
				<el-form-item label="LOGO" prop="logo">
					<el-upload class="avatar-uploader" action="/api/population/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
						:headers="{ token: authorToken }">
						<img v-if="gridForm2.logo" :src="gridForm2.logo" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="管理员" prop="uid">
					<el-select v-model="gridForm2.uid" placeholder="请选择管理员" class="auth-select">
						<el-option v-for="item in userList" :key="item.id + ''" :label="item.realName"
							:value="item.id + ''"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="介绍说明">
					<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="请输入说明"
						v-model="gridForm2.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="roleDialogClose">取 消</el-button>
				<el-button type="success" @click="confirmGrid">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/gridManage.js'
import { getUserList } from '@/api/userAdmin.js'
import { getCommunity } from '@/api/communityManagement.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			authorToken: sessionStorage.authorToken,
			props: {
				multiple: true,
				// lazy: true,
				label: 'name',
				value: 'code',
				// async lazyLoad (node, resolve) {
				// 	console.info(node, 'node')
				// 	if (!node.parent) {
				// 		const data = await API.getBding({
				// 			area: node.value
				// 		})
				// 		if (data.code === 200) {
				// 			resolve(data.data.map(v => {
				// 				return {
				// 					name: v,
				// 					code: v,
				// 					leaf: true,
				// 				}
				// 			}))
				// 		}
				// 	}
				// 	resolve([])
				// }
			},
			roleInfo: [],
			formRules2: {
				uid: [
					{
						required: true,
						message: '请选择管理员',
						trigger: 'change'
					},
				],
				logo: [
					{
						required: true,
						message: '请上传logo',
						trigger: 'change'
					},
				],
			},
			formRules: {
				gridName: [
					{
						required: true,
						message: '请输入网格名称',
						trigger: 'blur'
					},
					{
						required: true,
						message: '请输入2到30位字符',
						min: 2,
						max: 30,
						trigger: 'blur'
					}
				],
				uid: [
					{
						required: true,
						message: '请选择管理员',
						trigger: 'change'
					},
				],
				areaBding: [
					{
						required: true,
						message: '请选择',
						trigger: 'change'
					},
				],
			},
			labelPosition: 'rigin',
			gridForm: {
				type: 2, //1.总网格 2.具体网格
				logo: '',
				gridName: '',
				uid: '',
				// realName: '',
				areaBding: [],
				// color: '',
				remark: '',
				// ctime: '',
				coid: '',
				// ds: ''
			},
			gridForm2: {
				uid: '',
				type: 1,
				remark: '',
				logo: ''
			},
			dialogVisible: false,
			roleDialogVisible: false,
			dialogFormTitle: '添加网格',
			tableHeaderColor: '',
			tableData: [],
			paginatedUsers: [],
			loading: true,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 1000
			},
			roleList: [],
			currentUserId: '',
			workLimitList: [],
			userList: [],
			communityList: [],
			gridInfo: {}
		}
	},
	created () {
		this.getUserList()
		this.getCommunity()
		// this.getRoleList()
		// this.getWorkLimitList()
		this.getGridList()
	},
	methods: {
		handleAvatarSuccess (res, file) {
			console.info(res)
			if (res.code == 200) {
				this.gridForm2.logo = res.data.link || ''
			}
			// this.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload (file) {
			const isJPG = file.type === 'image/jpeg';
			const isPNG = file.type === 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isJPG && !isPNG) {
				this.$message.error('上传头像图片只能是 JPG、PNG 格式!');
			}
			// if (!isPNG) {
			// 	this.$message.error('上传头像图片只能是 PNG 格式!');
			// }
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return (isJPG || isPNG) && isLt2M;
		},
		editGrid () {
			const { uid, remark, logo } = this.gridInfo
			this.gridForm2 = {
				uid,
				type: 1,
				remark,
				logo
			}
			this.roleDialogVisible = true
		},
		async getCommunity () {
			const data = await getCommunity({
				pageSize: 100,
				pageNum: 1
			})

			if (data.code === 200) {
				this.communityList = data.data || []
				this.communityList.forEach((item, index) => {
					this.getCommunityBding(item.code, index)
				})
			}
		},
		async getUserList () {
			const data = await getUserList({
				pageSize: 100,
				pageNum: 1
			})
			if (data.code === 200 && data.data) {
				this.userList = data.data.list || []
			}
		},
		/**
		 * 获取用户列表
		 */
		async getGridList () {
			this.loading = true
			try {
				// const { pageSize, pageNum } = this.page
				const data = await API.getGridList({
					// pageNum,
					// pageSize
				})
				if (data.code === 200 && data.data) {
					this.gridInfo = data.data.gridInfo || {}
					this.tableData = data.data.list || []
					this.page.total = data.data.list.length
					this.page.pageSize = this.page.total
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		/**
		 * 获取工作条线
		 */
		async getWorkLimitList () {
			try {
				const data = await API.getWorkLimitList()

				if (data.code === 200) {
					this.workLimitList = data.data || []
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 修改用户状态
		 */
		async changeStatus (val, { id, nickName }) {
			try {
				const data = await API.updateStatus({ status: val, id })
				if (data.code === 200) {
					const message = `已成功${val === 1 ? '启用' : '关闭'} ${nickName} 用户！`
					this.$notify.success({
						title: '提示',
						message
					})
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 删除用户账号
		 */
		async delGrid ({ id, gridName }) {
			try {
				const data = await API.delGrid({ id })

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功删除 ${gridName} ！`,
						title: '提示'
					})
					this.page.pageNum = 1
					this.getGridList()
				}
			} catch (error) {
				console.error('delGrid: ', error)
			}
		},
		async addGrid () {
			try {
				this.btnLoading = true
				// const 
				// console.info(this.gridForm.areaBding.map(v => v.join('-')), this.gridForm.areaBding)
				const data = await API.addGrid({
					...this.gridForm,
					areaBding: this.gridForm.areaBding.map(v => v.join('-'))
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getGridList()
					this.$notify.success({
						title: '提示',
						message: '添加网格成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async updateGrid () {
			try {
				this.btnLoading = true
				const data = await API.updateGrid({
					...this.gridForm,
					id: this.currentUserId,
					areaBding: this.gridForm.areaBding.map(v => v.join('-'))
					// password: ''
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getGridList()
					this.$notify.success({
						title: '提示',
						message: '网格修改成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async confirmGrid () {
			const data = await API.updateGrid({
				...this.gridInfo,
				...this.gridForm2
				// id: this.currentUserId,
				// areaBding: this.gridForm.areaBding.map(v => v[1])
				// password: ''
			})
			if (data.code === 200) {
				// this.handleCloseForm()
				// this.page.pageNum = 1
				// this.getGridList()
				// this.roleDialogVisible = false
				this.getGridList()
				this.roleDialogClose()
				this.$notify.success({
					title: '提示',
					message: '社区网格修改成功'
				})
			}
		},
		async getGridInfo () {
			try {
				const data = await API.getGridInfo({
					id: this.currentUserId
				})
				if (data.code === 200 && data.data) {
					// this.roleInfo = data.data.map(t => t.id)
					const info = data.data
					Object.keys(this.gridForm).forEach(key => {
						if (info[key]) {
							this.gridForm[key] = info[key]
						}
					})
					const areaArr = info.areaBding.map(v => {
						const arr = v.split('-')
						// this.getCommunityBding(c)
						return [arr[0], arr[1]]
					})
					this.gridForm.areaBding = areaArr
					console.info(areaArr, 'areaArr')
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getCommunityBding (code, index) {
			const data = await API.getBding({
				area: code
			})
			if (data.code === 200) {
				// const index = this.communityList.findIndex(item => item.code == code)
				this.communityList[index].children = data.data.map(bding => {
					return {
						name: bding + '幢',
						code: bding,
						leaf: true,
					}
				})
			}
		},
		/**
		 * 初始化密码
		 */
		async createPassword () {
			try {
				this.$notify.success({
					title: '提示',
					message: '初始化密码成功'
				})
			} catch (error) {
				console.error(error)
			}
		},
		subUser () {
			this.$refs.dialogForm.validate(validator => {
				if (!validator) return
				if (this.dialogFormTitle === '添加网格') {
					this.addGrid()
				} else {
					this.updateGrid()
				}
			})
		},
		roleDialogClose () {
			// this.roleInfo = []
			// this.currentUserId = ''
			this.roleDialogVisible = false
		},
		// handleClose () { },
		addUser () {
			this.dialogFormTitle = '添加网格'
			this.dialogVisible = true
		},
		editGridInfo ({ id }) {
			this.currentUserId = id
			this.dialogFormTitle = '编辑网格'
			this.getGridInfo()
			this.dialogVisible = true
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getGridList()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
		handleCloseForm () {
			this.currentUserId = ''
			this.$refs.dialogForm.resetFields()
			this.gridForm.remark = ''
			this.dialogVisible = false
		}
	},
}
</script>

<style lang="scss" scoped>
.sq-grid {
	display: flex;
	margin: 0 15px;
	padding: 15px;
	width: 100%;
	background-color: #fff;
	border-radius: 2px;
	font-size: 16px;

	&-img {
		border: 1px #999;
		width: 200px;

		::v-deep .el-image {
			width: 100%;
			height: 150px;
			line-height: 150px;
			text-align: center;
			box-sizing: border-box;

			.image-slot {
				border: 1px solid #e6e6e6;
				height: 99%;
				// box-sizing: border-box;

				i {
					font-size: 26px;
				}
			}
		}
	}

	&-info {
		margin-left: 15px;
		line-height: 30px;
	}

	&-btn {
		display: flex;
		align-items: center;
		margin-left: 5%;
		padding-left: 3%;
		border-left: 1px solid #e6e6e6;
	}
}

.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}

::v-deep .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 160px;
	height: 160px;
	line-height: 160px;
	text-align: center;
}

.avatar {
	width: 160px;
	height: 160px;
	display: block;
}
</style>