/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-12-19 17:52:43
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-20 17:40:43
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\gridManage.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getGridList = (params) => {
	return Http({
		url: '/population/gridList',
		method: 'get',
		params
	})
}

export const addGrid = (params) => {
	return Http({
		url: 'population/gridAdd',
		method: 'post',
		data: params
	})
}

export const getGridInfo = (params) => {
	return Http({
		url: `/population/gridInfo/${params.id}`,
		method: 'get'
	})
}

export const delGrid = (params) => {
	return Http({
		url: `/population/gridDel/${params.id}`,
		method: 'get'
	})
}

export const updateGrid = (params) => {
	return Http({
		url: '/population/gridUpdate',
		method: 'post',
		data: params
	})
}

export const getBding = (params) => {
	// /population/findBdingInfo
	return Http({
		url: '/population/findBdingInfo',
		method: 'get',
		params
	})
}